.clients{
    background-image: url(../../assets/test-bg.jpg);
    height: 500px;
    background-repeat: no-repeat; 
    background-size: cover;
    position: relative;
}

.clients .black_border{
    background-color: black;
    height: 100px;
    width: 100%;
    position: absolute;
    bottom: 90px;
}
.clients .clients-head{
    padding-top: 50px;
}

.clients .clients-head h2{
    font-size: 40px;
    line-height: 48px;
    color: #000;
    text-align: center;
    font-weight: 700;
}

.clients .clients-slider{
    display: flex;
    justify-content: center;
    align-self: center;
}

@media (max-width: 770px){
    .clients .black_border{
        display: none;
    }
.clients{
    height: auto;
}

}