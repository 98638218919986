.price-card {
    border-radius: 20px;
    min-height: 300px;
}

.price-card .head {
    position: relative;
    /* background: -webkit-linear-gradient(#030c2f, #0047e7); */
    background: linear-gradient(#7f9ee3,#0047e7);
    padding: 10px 0;
    min-height: 180px;
    border-radius: 20px 20px 0 0;
}

.price-card .head img {
    position: absolute;
    width: 121px;
    height: auto;
    top: 0;
}

.price-card .head h3 {
    color: #ffffff;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    margin-top: 20px;
}

.price-card .head .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.price-card .head .flex p {
    text-align: center;
    font-size: 30px;
    line-height: 58px;
    text-transform: uppercase;
    color: #72737a;
    font-weight: 700;
    text-decoration: line-through;
    margin-top: 20px;
}

.price-card .head .flex h3 {
    text-align: center;
    font-size: 50px;
    line-height: 58px;
    text-transform: uppercase;
    color: #ffb301;
    font-weight: 700;
}

.price-card .content {
    height: 280px;
    padding: 25px 15px;
    background-color: white;
    overflow-y: auto;
}

.price-card .content::-webkit-scrollbar {
    width: 4px;
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
}

.price-card .content::-webkit-scrollbar-thumb {
    background-color: #0046e4;
    outline: 1px solid #0046e4;
}

.price-card .content .text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-bottom: 8px;
    margin-bottom: 15px;

}

.price-card .content .text svg {
    color: #0044dc;
    font-size: 14px;
}

.price-card .content .text p {
    color: #000;
    font-size: 14px;
    line-height: 16px;
}

.price-card .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 12px 0;
    background-color: white;
    flex-wrap: wrap;
}

.price-card .btn-container a {
    background: #1d9bff;
    line-height: 24px;
    height: 40px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 5px;
    border: none;

}

.price-card .order {
    background: -webkit-linear-gradient(#ffb301, #c78b00);
    border-radius: 0 0 20px 20px;
    cursor: pointer;
}

.price-card .order p {
    color: #fff;
    font-size: 20px;
    line-height: 52px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    text-align: center;
}

@media (max-width: 1050px) {
    .price-card .head h3 {
        font-size: 20px;
    }
}