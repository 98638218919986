.process-container{
    padding: 30px 0;
    width: 70%;
}

.process-container h2{
    color: #0044dc;
    font-size: 40px;
    line-height: 48px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
}

.process-container p{
    color: #6b6b6b;
    font-size: 16px;
    text-align: center;
    padding-bottom: 30px;
} 

.process-container .single-process{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.process-container .single-process .process-thumb{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
}

.process-container .single-process .process-thumb img{
    margin-bottom: 10px;
    /* margin: auto; */
}

.process-container .single-process .body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.process-container .single-process .body h6{
    color: #222222;
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
    text-align: center;
}

.process-container .single-process .body p{
    color: #666666;
    font-size: 13px;
    font-weight: normal;
    line-height: 17px;
}

@media (max-width: 1024px){
.process-container .single-process .background1{
    display: none;
}
.process-container .single-process .background2{
    display: none;
}

}



