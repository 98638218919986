.footer{
    width: 80%;
    padding-top: 20px;
    padding-bottom: 5px;
}

.footer p{
    font-size: 11px;
    color: #fff;
    text-align: center;
    padding-bottom: 5px;
}