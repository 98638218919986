.introuction {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url(../../assets/bg.jpg);
    background-repeat: no-repeat;
    padding-bottom: 45px;
}

.container .experience {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 8px 1px rgb(119 119 119 / 81%);
}

.container .experience .experience-div {
    width: 80%;
    display: flex;
}

.container .experience .experience-div .shadow-sep {
    background-image: url(../../assets/shadow-sep.png);
    width: 2%;
    background-repeat: no-repeat;
}

.container .experience .experience-text {
    width: 34%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .experience .experience-text p {
    text-align: center;
    font-size: 15px;
}

.container .experience .experience-img {
    width: 64%;
}

.container .experience .experience-img img {
    max-width: 100%;
    height: auto;
}

.container .glimpse-of-our-work {
    background-image: url(../../assets/wbg2.webp);
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 0;
}

.container .creative-logos {
    padding: 40px 0;
    background: linear-gradient(0deg, #001591, #83a9ff);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container .creative-logos h3 {
    color: #fff;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 15px;
}

.container .creative-logos h2 {
    color: #fff;
    font-size: 50px;
    line-height: 58px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 40px;
}

.container .creative-logos .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.container .creative-logos .call {
    display: flex;
    justify-content: center;
    align-items: center;

}

.container .creative-logos .call p {
    color: #fff;
    text-align: left;
    font-weight: 500;
    font-size: 16px
}

.container .creative-logos .call img {
    margin-right: 20px;
}

.container .creative-logos .chat {
    display: flex;
    justify-content: center;
    align-items: center;

}

.container .creative-logos .chat p {
    color: #fff;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

}

.container .creative-logos .chat h6 {
    color: #fff;
    text-align: left;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
}

.container .creative-logos .chat img {
    margin-right: 20px;
}

.container .logo-prices {
    background-image: url(../../assets/wbg3.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container .logo-process {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.container .logo-ask {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(0deg, #001591, #83a9ff);
}

.container .logo-belonging {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
}

.container .logo-awards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
}

.container .logo-footer-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #292a32;
}

.container .footer-bootom {
    background: #1b1c22;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 770px) {
    .container .experience {
        display: none;
    }

    .container .glimpse-of-our-work {
        background-repeat: repeat;
    }
}

@media (max-width: 550px) {
    .container .creative-logos h2 {
        font-size: 38px;
    }
}