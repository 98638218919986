.our-work-container{
    width: 80%;
    padding: 30px 0;
}

.our-work-container .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} 

.our-work-container .header h2{
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 40px;
    color: #333;
}
.our-work-container .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 40px;
    flex-wrap: wrap;

}

.our-work-container .btn-container button{
    border-radius: 5px;
    border: none;
    padding: 20px 25px;
    background: #f5f5f5;
    color: #000;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.active-btn{
    background: #021893 !important;
    color: #fff !important;
}
.our-work-container .mascot-design{
    height: max-content;
}

@media (max-width: 550px){
    .our-work-container{
        padding: 15px 0;
    }
}