.awards {
    width: 70%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.awards h2 {
    color: #0044dc;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    font-weight: 600;
    margin: 10px 0;
}

.awards p {
    color: #6b6b6b;
    font-size: 16px;
    margin-bottom: 30px;
    text-align: center;
}



.awards .awards-img img {
    max-width: 90%;
    height: auto;
    filter: grayscale(100%);
    cursor: pointer;
}

.awards .awards-img img:hover {
    filter: grayscale(0);
}

@media (max-width: 770px) {

    .awards {
        width: 100%;
    }

    .awards .awards-img {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}