.footer-top {
    width: 70%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.footer-top .head h3 {
    color: #fff;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 10px;
}

.footer-top .head h2 {
    font-size: 40px;
    line-height: 48px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin-bottom: 50px;
}

.footer-top .footer-form .ant-input-affix-wrapper {
    border: none;
    width: 100%;
    padding: 11px;
    background: transparent;
    border-bottom: 1px solid #fff;
    font-size: 18px;
    color: #fff;
}

.footer-top .footer-form .form-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.footer-top .footer-form .form-btn button {
    padding: 30px 60px;
    background: #ffb301;
    color: #000;
    border-radius: 0px;
    font-size: 20px;
    font-weight: 700;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-top .socialize {
    padding-top: 11px;
}

.footer-top .socialize h4 {
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px
}

.footer-top .icons-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.footer-top .icons-div svg {
    color: #fff;
    text-transform: uppercase;
    font-size: 55px;
    text-align: center;
    line-height: 50px;
    padding: 10px;
    border-radius: 100%;
    border: 1px solid #fff;
    cursor: pointer;
}

.footer-top .border-bottom {
    border-bottom: 1px solid #fff;
    padding-bottom: 25px;
    border-bottom-style: dotted;
}

.footer-top .border-bottom h4 {
    color: #fff;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 0px;
    margin-top: 25px
}

.footer-top .payment h4 {
    color: #fff;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 25px;
    margin-top: 25px
}

.footer-top .trusted{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 40px;
}

.footer-top .trusted img{
    width: 100px;

}
@media (max-width: 770px) {
    .footer-top .head h2 {
        font-size: 38px;
    }
}

@media (max-width: 350px) {
    .footer-top .cards-img img {
        width: 250px;
    }
}