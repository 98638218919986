.banner-container{
    width: 80%;
    height: max-content;
}
.banner-container .ant-row{
    align-items: center;
}
.banner-content{
    margin-top: 45px;
}

.banner-content .headings h3{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    line-height: 30px;
}

.banner-content .headings h2{
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    line-height: 30px;
}

.banner-content .headings p{
    color: #fff;
    font-size: 13px;
  
}

.banner-content .form{
    background: rgba(0, 0, 0, 0.49);
    border-top: 5px solid #bf2025;
    margin: 20px auto 0;
    
}

.banner-content .form h2{
    font-size: 26px;
    line-height: 45px;
    margin-bottom: 0;
    font-weight: 600;
    text-align: center;
    color: #fff;
}

.banner-content .form p{
    font-size: 20px;
    font-weight: 800;
    line-height: 20px;
    text-align: center;
    color: #ff9800;
    margin: 10px 0 15px;
}

.banner-content .form .login-form .ant-input-affix-wrapper{
    border: none;
    width: 100%;
    padding: 11px;
    background: transparent;
    border-bottom: 1px solid #fff;
    font-size: 18px;
    color: #fff;
}

.banner-container .form .login-form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.banner-container .form .login-form .form-btn{
    width: 100%;
    margin-top: 11px;
    margin-bottom: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner-container .form .login-form .form-btn button{
    padding: 10px 33px;
    color: #FFF;
    background: #064abe;
    border-radius: 5px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-container .banner-content .caution{
    margin-top: 20px;
}

.banner-container .banner-content .caution p{
    color: #fff;
    font-size: 13px;
    line-height: 0;
    margin: 0;
}

.banner-container .banner-content .caution span{
    color: #fff;
    font-weight: bold;
}

.banner-container .slider{
    background-image: url(../../assets/frame.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 400px;
    width : 100%;
 position: relative;
}
.carousel{
    padding-top: 70px;
}
.carousel img{
    display : flex;
    margin: 0 auto;
    height: 250px;
    width : 90%;

    object-fit : contain;
}

.ant-input-prefix{
    margin-right: 10px;
}

@media (max-width: 1050px) {
    .banner-container{
        width: 95%;
        height: max-content;
    }
    .banner-content .headings p{
        text-align: center;
    }
}

@media (max-width: 550px) {
   .carousel img{
    width : 80%;
   }
.banner-container .slider{
    height: 300px;
}
.carousel{
    padding-top: 20px;
}

}
