.industries{
    width: 90%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.industries .head p{
    color: #000;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 10px;
}

.industries .head h2{
    color: #0044dc;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px;
}

.industries .industries-flex{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    min-height: 85px;
}

.industries .industries-flex h4{
    font-weight: 500;
    color: #292a31;
    font-size: 16px;
    line-height: 24px;
}

@media (max-width:1050px){
    .industries .industries-flex{
        gap: 10px;
    }
.industries .industries-flex h4{
    font-size: 14px;
}
}
