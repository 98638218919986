.main-header {
    width: 80%;
    padding-top: 15px;
    height: 125px;
}

.main-header .logo img {
    height: 100px;
}

.main-header .contact-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.main-header .contact-info .chat-info {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-header .contact-info .chat-info svg {
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin-right: 5px;
}

.main-header .contact-info .chat-info p {
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.main-header .contact-info .division {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.main-header .contact-info .call-info {
    display: flex;
    justify-content: center;
    align-items: center
}

.main-header .contact-info .call-info svg {
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin-right: 5px;
}

.main-header .contact-info .call-info p {
    color: white;
    font-size: 16px;
    font-weight: 600;
}

@media (max-width: 1050px) {
    .main-header {
        width: 100%;
    }
}


@media (max-width: 770px) {
    .main-header {
        height: max-content;
    }

    .main-header .logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main-header .contact-info {
        flex-direction: column;
    }

    .main-header .contact-info .division {
        display: none ;
    }

}