.order-form{
    padding: 15px 10px;

}

.order-form .btn-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.order-form .btn-container button{
    background-color: #1d9bff;
    border: none;
}