.questions {
    padding: 60px 0;
    width: 75%;
}

.questions h5 {
    color: #fff;
    font-size: 26px;
    line-height: 30px;
    margin: 0;
    font-weight: 500;
    text-align: left;
}

.questions .we-are-here h3 {
    color: #fff;
    font-size: 42px;
    line-height: 50px;
    margin: 0;
    font-weight: 700;
    text-align: left;
}

.questions .call-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.questions .call-flex .call-info p {
    color: #fff;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.questions .call-flex .call-info h6 {
    color: #fff;
    text-align: left;
    font-weight: 700;
    font-size: 18px;
}

.questions .chat-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

}

.questions .chat-flex .chat-info p {
    color: #fff;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.questions .chat-flex .chat-info h6 {
    color: #fff;
    text-align: left;
    font-weight: 700;
    font-size: 18px;
}

.questions .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.questions .btn-container button {
    background: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #000;
    padding: 22px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

@media (max-width: 1030px){
    .questions {
        width: 90%;
    }

}

@media (max-width: 770px){
    .questions .chat-flex{
        justify-content: flex-start;
    }
}

@media (max-width: 550px){
    .questions .chat-flex{
        justify-content: center;
    }
}