.client-says {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    gap: 40px;
}

.client-says .client-img img {
    border-radius: 100%;
    border: 10px solid #fff;
}

.client-says .client-reviews p {
    color: #666666;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    min-height: 130px;
    padding-top: 5px;
}

.client-says .client-reviews h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    margin-top: 45px;
    color: #fff;
}

@media (max-width: 1050px) {
    .client-says {
        width: 95%;
        gap: 20px;
    }
}

@media (max-width: 770px) {
    .client-says {
        flex-direction: column;
        gap: 10px;
    }

    .client-says .client-reviews p {
        text-align: center;
    }

    .client-says .client-reviews {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .client-says .client-reviews h4 {
        color: #000;
        margin-bottom: 20px;
    }

    .client-says .client-img {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .client-says .client-img img {
        width: 80%;
    }
}