.price-container {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.price-container .head h2 {
  font-size: 40px;
  line-height: 48px;
  color: #0044dc;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin: 0 0 40px;
}

.tabs-cotainer {
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
}
.tabs-cotainer .top {
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 1rem;
  row-gap: 1rem;
  flex-wrap: wrap;
}
.tabs-cotainer .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  row-gap: 1rem;
  flex-wrap: wrap;
}
.tabs-cotainer .custom-tab {
  width: max-content;
  font-weight: 600;
  color: black;
  border-radius: 15px;
  /* background: -webkit-linear-gradient(#030c2f, #0047e7); */
  /* background: linear-gradient(#ffff,#0047e7); */
  font-size: 17px;
  padding: 10px 25px;
  background: transparent;
  border-color: transparent !important;
  transition: all 0.3s ease;
}
.tabs-cotainer .custom-tab:hover {
  cursor: pointer;
  background: linear-gradient(#7f9ee3, #0047e7);
  color: white;
}
.tabs-cotainer .active-tab {
  background: linear-gradient(#7f9ee3, #0047e7);
  color: white;
}

@media (max-width: 1050px) {
  .price-container {
    width: 95%;
  }
}
@media (max-width: 500px) {
  .price-container .head h2 {
    font-size: 32px;
  }
  .tabs-cotainer .top {
    flex-direction: column;
  }
  .tabs-cotainer .bottom {
    flex-direction: column;
  }
}
