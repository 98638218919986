.cards-container{
    border: #e9e9e9 2px solid;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.cards-container img{
    width: 100%;
}
