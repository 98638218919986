.mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background);
  padding: 10px 4px;
  padding-right: 14px;
}
.m-drawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.m-header {
  background-color: var(--background) !important;
  color: var(--text);
}
.m-children {
  padding: 14px;
  background-color: var(--background) !important;
  min-height: 89.5vh;
}
.backArrow {
  font-size: 20px;
  color: var(--text);
}
.m-drawer .logo {
  height: 20px;
}

@media (max-width: 800px) {
  .ant-drawer-content-wrapper {
    width: 270px !important;
  }
}

@media (max-width: 650px) {
  .sidebar-menu {
    padding-left: 0 !important;
  }
  .logout-menu {
    padding-left: 24px !important;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
